// vos-用户设置
<template>
  <div class="menu">
    <!-- 1.头部搜索查询 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          数据列表
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button icon="el-icon-plus" class="button-el" size="medium" @click="addDialogVisible=true">添加
            </el-button>
          </div>
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="searchDialogVisible=true">查询
            </el-button>
          </div>
          <div class="ml-10">
            <div class="font-refresh-out flex-nowrap-center-center" @click="searchReset">
              <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
              <i v-else class="el-icon-loading loding-color"></i>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 2.主体列表 -->
    <el-row>
      <el-col :span="24">
        <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" size="mini"
          :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" default-expand-all
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
          <el-table-column label="主叫前缀">
            <template #default="scope">{{ scope.row.telAPrefix == null?'-':scope.row.telAPrefix}}</template>
          </el-table-column>
          <el-table-column label="行业">
            <template #default="scope">{{ scope.row.industry == null?'-':scope.row.industry}}</template>
          </el-table-column>
          <el-table-column v-if="userType=='system'" label="用户名">
            <template
              #default="scope">{{ scope.row.nickName == null|| scope.row.nickName == ''?'-': scope.row.nickName}}</template>
          </el-table-column>
          <el-table-column label="创建日期">
            <template
              #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-tooltip content="删除" placement="top" effect="light">
                <el-button type="danger" plain size="mini" icon="el-icon-delete" @click="handleDelete(scope.row)"
                  circle>
                </el-button>
              </el-tooltip>
              <el-tooltip content="修改" placement="top" effect="light">
                <el-button type="primary" plain size="mini" icon="el-icon-edit" @click="handleEdit(scope.row)" circle>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 3.分页器 -->
    <div class="pager">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="searchFrom.page" background :page-sizes="[10, 50, 100, 200]" :page-size="searchFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 新增用户模态框 -->
    <el-dialog title="新增用户" v-model="addDialogVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="600px">
      <el-form :rules="rules" label-position="right" label-width="100px" size="small" :model="addFrom" ref="addform">
        <el-form-item label="主叫前缀" prop="telAPrefix">
          <el-input v-model="addFrom.telAPrefix" class="input-searchFrom-width" placeholder="请输入需要查询的主叫前缀" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-input v-model="addFrom.industry" class="input-searchFrom-width" placeholder="请输入需要查询的行业" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item v-if="userType=='system'" label="用户">
          <div class="input-searchFrom-width">
            <el-select v-model="addFrom.uid" placeholder="请选择用户" style="width: 100%" clearable filterable>
              <el-option v-for="(item, index) in userList" :key="index" :label="item.nickname" :value="item.uid">
                <div class="flex-nowrap-space-between">
                  <div :class="item.status == 1?'no-red':''">
                    <span>{{ item.nickname }}</span>
                    <span class="ml-5">(Id:{{ item.uid }})</span>
                  </div>
                  <div v-if="item.status == 1" class="no-red">禁</div>
                </div>
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button class="cancel" plain @click="handleCancle('addform')" round>
              取消
            </el-button>
            <el-button class="confirmAdd" @click="handleSubmit('addform')" round>
              确认
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 查询用户模态框 -->
    <el-dialog title="查询用户" v-model="searchDialogVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="600px">
      <el-form label-position="right" label-width="100px" size="small" :model="searchFrom" ref="searchFrom">
        <el-form-item label="主叫前缀">
          <el-input v-model="searchFrom.telAPrefix" class="input-searchFrom-width" placeholder="请输入需要查询的主叫前缀"
            size="mini"></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="searchFrom.industry" class="input-searchFrom-width" placeholder="请输入需要查询的行业" size="mini">
          </el-input>
        </el-form-item>
        <el-form-item v-if="userType=='system'" label="用户">
          <div class="input-searchFrom-width">
            <el-select v-model="searchFrom.uid" placeholder="请选择用户" style="width: 100%" clearable filterable>
              <el-option v-for="(item, index) in userList" :key="index" :label="item.nickname" :value="item.uid">
                <div class="flex-nowrap-space-between">
                  <div :class="item.status == 1?'no-red':''">
                    <span>{{ item.nickname }}</span>
                    <span class="ml-5">(Id:{{ item.uid }})</span>
                  </div>
                  <div v-if="item.status == 1" class="no-red">禁</div>
                </div>
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker :style="{width:'400px'}" v-model="valueTime" type="daterange" format="YYYY/MM/DD"
            value-format="YYYY-MM-DD" clearable unlink-panels range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :shortcuts="shortcuts" size="mini" />
        </el-form-item>
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button class="cancel" plain @click="searchReset" round>
              重置
            </el-button>
            <el-button class="confirmAdd" @click="handleSearch" round>
              查询
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 编辑用户模态框 -->
    <el-dialog title="编辑用户" v-model="editDialogVisible" :close-on-click-modal="false" :destroy-on-close="true"
      width="600px">
      <el-form :rules="rules" label-position="right" label-width="100px" size="small" :model="editFrom" ref="editFrom">
        <el-form-item label="主叫前缀" prop="telAPrefix">
          <el-input v-model="editFrom.telAPrefix" class="input-searchFrom-width" placeholder="请输入主叫前缀" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-input v-model="editFrom.industry" class="input-searchFrom-width" placeholder="请输入行业" clearable
            size="mini">
          </el-input>
        </el-form-item>
        <el-form-item v-if="userType=='system'" label="用户">
          <div class="input-searchFrom-width">
            <el-select v-model="editFrom.uid" placeholder="请选择用户" style="width: 100%" clearable filterable>
              <el-option v-for="(item, index) in userList" :key="index" :label="item.nickname" :value="item.uid">
                <div class="flex-nowrap-space-between">
                  <div :class="item.status == 1?'no-red':''">
                    <span>{{ item.nickname }}</span>
                    <span class="ml-5">(Id:{{ item.uid }})</span>
                  </div>
                  <div v-if="item.status == 1" class="no-red">禁</div>
                </div>
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="flex-nowrap-flex-end">
            <el-button class="cancel" plain @click="handleEditCancle('editFrom')" round>
              取消
            </el-button>
            <el-button class="confirmAdd" @click="handleEditSubmit('editFrom')" round>
              确认
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store/index'
import {
  vosSettingList,
  vosSettingAdd,
  vosSettingDel,
  vosSettingEdit,
  vosSettingSearch,
} from '@/api/open/voiceCheck/voiceUser'
import { defaultTime } from '@/utils/timeToChoose.js'
import { IconFont } from '@/utils/iconfont.js'
import userts from '@/api/web/user'
import { ElMessageBox } from 'element-plus'
import { h } from 'vue'
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      userType: store.state.userType,
      shortcuts: [
        {
          text: '上周',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            return [start, end]
          },
        },
        {
          text: '上月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            return [start, end]
          },
        },
        {
          text: '前三月',
          value: () => {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            return [start, end]
          },
        },
      ],
      addDialogVisible: false, //新增用户模态框
      //新增参数
      addFrom: {
        uid: '', //用户
        telAPrefix: '', //主叫前缀
        industry: '', // 行业
      },

      valueTime: '', //选中时间段
      userList: [], // 查询用户的下拉集合
      searchDialogVisible: false, //查询用户模态框
      //查询参数
      searchFrom: {
        page: 1,
        pageSize: 10,
        telAPrefix: '', //主叫前缀
        uid: '', //用户ID
        industry: '', // 行业
        createTimeBegin: '',
        createTimeEnd: '',
      },
      total: 0,
      editDialogVisible: false, //编辑用户模态框
      //编辑参数
      editFrom: {
        telAPrefix: '', //主叫前缀
        id: '', 
        industry: '', // 行业
        uid: '', // 用户ID
      },
      tableData: [], //菜单表
      loading: true,
      rules: {
        telAPrefix: [
          {
            required: true,
            message: '请输入主叫前缀',
            trigger: 'blur',
          },
        ],
        industry: [
          {
            required: true,
            message: '请输入行业',
            trigger: 'blur',
          },
        ],
      },
      currUser: null
    }
  },
  mounted() {
    this.getDataList(true)
     if(this.userType=='system'){
       this.getVoiceName()
     }
  },
  created() {
    this.currUser = this.$store.getters.user
  },
  methods: {
    //新增用户---->取消
    handleCancle(name) {
      this.addFrom = {
        uid: '',
        telAPrefix: '',
        industry: '',
      }
      this.$refs[name].resetFields()
      this.addDialogVisible = false
    },
    //新增用户---->确认
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (!valid) {
          return
        } else {
          this.addFrom.uid = this.currUser.uid
          vosSettingAdd({ ...this.addFrom }).then((res) => {
            if (res.code !== 200) {
              return this.$message.error(res.message)
            } else {
              this.addFrom = {
                uid: '',
                telAPrefix: '',
                industry: '',
              }
              this.$refs[name].resetFields()
              this.addDialogVisible = false
              this.getDataList(true)
              this.$message.success('添加成功')
            }
          })
        }
      })
    },
    // 查询
    handleSearch() {
      this.searchDialogVisible = false
      this.getDataList(true)
    },
    // 查询重置
    searchReset() {
      this.searchFrom = {
        page: 1,
        pageSize: 10,
        telAPrefix: '', //主叫前缀
        uid: '', //用户ID
        industry: '', // 行业
        createTimeBegin: '',
        createTimeEnd: '',
      }
      // this.valueTime = defaultTime()
      this.getDataList(true)
    },
    // 查询用户的下拉
    getVoiceName() {
      userts.queryList({}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error(res.message)
        } else {
          this.userList = res.data
        }
      })
    },
    //删除用户
    handleDelete(row) {
      ElMessageBox({
        title: '提示消息',
        message: h('p', null, [
          h('span', null, '请再次确认是否删除 '),
          h('i', { style: 'color: #57B8FF' }, ` ${row.nickName}`),
          h('span', null, ' 的vos设置'),
        ]),
        showCancelButton: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      })
        .then(() => {
          vosSettingDel({ id: row.id }).then((res) => {
            if (res.code !== 200) {
              return this.$message.error(res.message)
            } else {
              this.$message.success('删除成功')
              this.getDataList(true)
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    //编辑用户
    handleEdit(row) {
      console.log('row :>> ', row);
      this.editFrom.telAPrefix = row.telAPrefix
      this.editFrom.id = row.id
      this.editFrom.uid = row.uid
      this.editFrom.industry = row.industry
      this.editDialogVisible = true
    },
    //编辑用户---->取消
    handleEditCancle(name) {
      this.$refs[name].resetFields()
      this.editDialogVisible = false
    },
    //编辑用户---->确认
    handleEditSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (!valid) {
          return
        } else {
          vosSettingEdit({ ...this.editFrom }).then((res) => {
            if (res.code !== 200) {
              return this.$message.error(res.message)
            } else {
              this.$refs[name].resetFields()
              this.editDialogVisible = false
              this.getDataList(true)
              this.$message.success('编辑成功')
            }
          })
        }
      })
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type
      if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0]
        this.searchFrom.createTimeEnd = this.valueTime[1]
      } else {
        this.searchFrom.createTimeBegin = ''
        this.searchFrom.createTimeEnd = ''
      }
      vosSettingList({
        ...this.searchFrom,
      }).then((res) => {
        if (res.code !== 200) {
          return this.$message.success(res.message)
        } else {
          this.tableData = res.data.records
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.searchFrom.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      this.searchFrom.page = val
      this.getDataList(true)
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;
  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}
.screening {
  margin-bottom: 0.9375rem;
}
.el-row {
  margin-bottom: 0.9375rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.editor {
  color: #0170ed;
  background: #e3f0ff;
}
.center-body {
  margin: 0 1.875rem;
}
.deletetable {
  color: #ff845b;
  background: #ffe4db;
}
.deletetable-other {
  margin-right: 1.25rem;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.screening-out {
  margin: 0 0.9375rem;
}
.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}
.pager {
  display: flex;
  justify-content: flex-end;
}
.pass {
  color: #67c23a;
}
.unpass {
  color: #f56c6c;
}
.priceColor {
  color: #409eff;
}
.talks {
  cursor: pointer;
}
.record {
  height: 5rem;
}
.dialogue {
  overflow-y: overlay;
  height: 25rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}
.recordSon {
  width: 100%;
}
.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}
.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.title-left {
  text-align: left;
}
.title-right {
  text-align: right;
}
.title-item {
  padding: 0.375rem;
}
.talkcolor {
  font-size: 1rem;
  color: #fff;
}
.Read {
  background: #abadbb;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.eltable {
  overflow-y: overlay;
}
</style>